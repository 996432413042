<template>
  <span class="label" :class="`label--${color}`" :style="`margin: ${margin};`">
    {{ message }}
  </span>
</template>

<script>
export default {
  name: "Label",

  props: {
    message: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: "green",
    },

    margin: {
      type: String,
      default: "auto 1rem",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors";

.label {
  padding: 0.25rem;
  font-size: 0.75rem;
  border-radius: 5px;
  &--green {
    background-color: color(signal, green);
  }
  &--yellow {
    background-color: color(signal, yellow);
  }
  &--red {
    background-color: color(signal, red);
  }

  &--transparent {
    background-color: transparent;
    border: 1px solid color(blue, light);
    color: color(blue, light);
  }
}
</style>
